<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Tambah Training Program
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer
        ref="formRules"
        v-slot="{ invalid }"
      >
        <b-row>
          <b-col md="8">
            <!-- form -->
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Nama"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama"
                      :rules="{ regex: /^[a-zA-Z0-9 ]*$/, min:3 , required: true }"
                    >
                      <b-form-input
                        placeholder="Auto"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                        disabled
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Jenis Event"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Jenis event"
                      rules="required"
                    >
                      <b-form-radio-group
                        v-model="eventType"
                        class="mt-50"
                        :options="eventOptions"
                        @change="handleChangeType($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="Training Center"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Training Center`"
                      rules="required"
                    >
                      <v-select
                        v-model="trainingCenterId"
                        class="w-100"
                        placeholder="Pilih Training Center"
                        :label="'name'"
                        :clearable="eventType === 'online' ? false : true"
                        :options="listTrainingCenter"
                        :reduce="tc => tc.id"
                        :disabled="eventType === 'online'"
                        @input="handleSelectTC($event)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Batch"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Batch"
                      rules="required|numeric"
                    >
                      <b-form-input
                        placeholder="Auto"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                        disabled
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Kuota"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Kuota"
                      rules="required|numeric"
                    >
                      <b-form-input
                        v-model="quota"
                        placeholder="Contoh: 80"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="Skill Role"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Skill Role`"
                      rules="required"
                    >
                      <v-select
                        v-model="skillId"
                        class="w-100"
                        placeholder="Pilih Skill Role"
                        :label="'name'"
                        :clearable="true"
                        :options="listSkill"
                        :reduce="skill => skill.id"
                        @input="selectSkillRole()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="Kurikulum"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Kurikulum`"
                      rules="required"
                    >
                      <v-select
                        v-model="curriculumId"
                        class="w-100"
                        placeholder="Pilih Kurikulum"
                        :label="'name'"
                        :clearable="true"
                        :options="listCurriculum"
                        :reduce="curriculum => curriculum.id"
                        :disabled="listCurriculum.length > 0 ? false : true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Keterampilan Lain"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Keterampilan Lain"
                      :rules="{ regex: /^[a-zA-Z0-9 ]*$/ }"
                    >
                      <v-select
                        v-model="otherSkills"
                        class="w-100"
                        placeholder="Masukkan keterampilan"
                        :label="'name'"
                        :clearable="true"
                        :options="listOtherSkill"
                        :reduce="skill => skill.id"
                        :multiple="true"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="Trainer"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Trainer`"
                      rules="required"
                    >
                      <v-select
                        v-model="trainerId"
                        class="w-100"
                        placeholder="Pilih Trainer"
                        :label="'name'"
                        :clearable="true"
                        :options="listTrainer"
                        :reduce="trainer => trainer.id"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="Tanggal"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Tanggal`"
                      rules="required"
                    >
                      <div class="input-group form-control p-0 pl-1">
                        <flat-pickr
                          v-model="date"
                          :config="config"
                        />
                        <div class="input-group-append">
                          <BButton
                            class="input-button"
                            variant="flat-warning"
                            size="md"
                            data-toggle
                          >
                            <span class="k-calendar-2" />
                          </BButton>
                        </div>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click.prevent="$router.back()"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary w-[200px]"
            :disabled="invalid || loadingSubmit"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import {
  required,
  min,
  max,
  numeric,
} from '@validations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { capitalize } from '@/libs/helpers'
import flatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import { alertError, alertSuccess } from '@toast'
import { eventTypeOptions } from './config'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  data() {
    return {
      required,
      min,
      max,
      numeric,
      capitalize,
      Indonesian,
      alertError,
      alertSuccess,

      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      id: this.$route.params.id,

      trainingCenterId: null,
      quota: '',
      curriculumId: null,
      skillId: null,
      trainerId: null,
      otherSkills: [],
      date: [],
      eventType: '',
      eventOptions: eventTypeOptions,

      listTrainingCenter: [],
      listSkill: [],
      listCurriculum: [],
      listOtherSkill: [],
      listTrainer: [],

      config: {
        mode: 'range',
        altInput: true,
        altFormat: 'j F Y',
        locale: Indonesian,
        wrap: true,
        minDate: 'today',
      },
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  async mounted() {
    this.getListSkills()
    this.getListTrainingCenter()
    this.getListOtherSkills()
    this.getListTrainer()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        training_center_id: this.eventType === 'online' ? null : this.trainingCenterId,
        training_type: this.eventType,
        quota: parseInt(this.quota, 10),
        skill_id: parseInt(this.skillId, 10),
        curriculum_id: this.curriculumId,
        other_skills: this.otherSkills.join(','),
        trainer_id: this.trainerId,
        start_date: this.date.split(' - ')[0],
        end_date: this.date.split(' - ')[1] ? this.date.split(' - ')[1] : this.date.split(' - ')[0],
      }

      const url = 'v1/training_programs/store'
      await komtimAxiosIns
        .post(url, data)
        .then(() => {
          const text = 'Berhasil menambahkan data'
          this.alertSuccess(text)

          this.$router.push({ name: 'training-program' })
        })
        .catch(error => {
          this.alertError(error)

          if (error.response.status === 422) {
            this.submitErrors = Object.fromEntries(
              Object.entries(error.response.data.data).map(
                ([key, value]) => [key, value[0]],
              ),
            )
          }
        })
    },
    toQuiz(params) {
      this.idTalent = params
      this.currentStep = 2
      this.buttonBackActive = false
    },
    handleBackFromQuiz() {
      this.currentStep = 1
      this.buttonBackActive = true
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2&limit=50`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkill = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListTrainingCenter() {
      this.loading = true
      this.offset = 0
      const url = 'v1/training_centers?limit=50'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listTrainingCenter = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListCurriculum() {
      this.loading = true
      this.offset = 0
      const url = `v1/curriculums?skill=${this.skillId}&limit=50`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listCurriculum = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListOtherSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=1&limit=50`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listOtherSkill = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListTrainer() {
      this.loading = true
      this.offset = 0
      const url = 'v1/trainers?limit=50'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listTrainer = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    selectSkillRole() {
      this.curriculumId = null
      if (this.skillId !== null) {
        this.getListCurriculum()
      }
    },
    handleChangeType(type) {
      if (type === 'online') this.trainingCenterId = 'Zoom Meeting'
      else this.trainingCenterId = null
    },
    handleSelectTC(val) {
      if (typeof val === 'number') this.eventType = 'offline'
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.vs__dropdown-menu {
  max-height: 180px;
}
  .flatpickr-input[readonly], .flatpickr-input ~ .form-control[readonly], .flatpickr-human-friendly[readonly] {
    border: none;
    padding: 0;
  }
</style>
<style lang="scss" scoped>
@import './style/Index.scss';
.form-control {
  line-height: 1.45;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
</style>
